import React, { useEffect, useState } from "react";
import SidebarFarmaceutico from "../../components/sidebar_farmaceutico/Sidebar_Farmaceutico";
import Axios from "axios";
import { formatDate } from "../../utils/date";
import { useHistory } from "react-router-dom";

const TelecEncerradaFarm = (props) => {
  Axios.defaults.withCredentials = true;

  let history = useHistory();

  const [lista, setLista] = useState([]);
  //const cpf = props.user.cpf;
  const [cpf, setCPF] = useState("");
  const [nomeFarmaceutico, setNomeFarmaceutico] = useState("");
  const abrir = (id) => {
    history.push({
      pathname: "/farmaceutico/detalhes",
      state: { detail: id },
    });
  };

  useEffect(() => {
    Axios.defaults.withCredentials = false;

    Axios.get("https://atendimento.mdhmlg.vsoares.dev.br:3002/login", {
      withCredentials: true,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }).then((response) => {
      if (response.data.loggedIn === true) {
        setCPF(response.data.user[0].cpf);
        setNomeFarmaceutico(response.data.user[0].nome);
        Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/telecEncerradas", {
          cpf: response.data.user[0].cpf,
        }).then((response) => {
          if (response.data.message) {
            alert(response.data.message);
          }
          console.log(response);
          setLista(response.data);
        });
      }
    });
  }, []);

  const chatTeleconsultoria = (id) => {
    history.push({
      pathname: "/farmaceutico/chatTeleconsultoria",
      state: { room: id, nomeFarmaceutico: nomeFarmaceutico },
    });
  };

  return (
    <>
      <SidebarFarmaceutico>
        <div class="main__container">
          <table class="content-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>SOLICITANTE</th>
                <th>DATA</th>
                <th>CHAT</th>
                <th>ÁREA INTERESSE</th>
                <th>STATUS</th>
                <th>AÇÃO</th>
              </tr>
            </thead>
            <tbody align="justify">
              {lista.map((item) => (
                <tr>
                  <th>{item.id}</th>
                  <th>{item.nome}</th>
                  <th>{formatDate(item.data_hora)}</th>
                  <th>
                    {" "}
                    <a
                      style={{ cursor: "pointer" }}
                      id={item.id}
                      onClick={(e) => chatTeleconsultoria(e.target.id)}
                    >
                      Abrir
                    </a>
                  </th>
                  <th>{item.area_interesse}</th>
                  <th>{item.status}</th>
                  <th>
                    <a
                      id={item.id}
                      style={{ cursor: "pointer" }}
                      onClick={(e) => abrir(e.target.id)}
                    >
                      Abrir
                    </a>
                  </th>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </SidebarFarmaceutico>
    </>
  );
};

export default TelecEncerradaFarm;
