import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import parse from "html-react-parser";
import "./respostaTelec.css";
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import SidebarFarmaceutico from "../../components/sidebar_farmaceutico/Sidebar_Farmaceutico";
import Axios from "axios";
import { formatDate } from "../../utils/date";
import { JitsiMeeting } from "@jitsi/react-sdk";
import styled from "styled-components";

const VideoChamadaFarmaceutico = (props) => {
  const location = useLocation();
  let history = useHistory();
  const [textSolicitado, setTextSolicitado] = useState("");
  const [solicitante, setSolicitante] = useState("");
  const [farmaceutico, setFarmaceutico] = useState("");
  const [data, setData] = useState("");
  const [area, setArea] = useState("");
  const [status, setStatus] = useState("");
  const [id, setId] = useState(location.state.detail);
  const [text, setText] = useState("");

  var room = "Teleconsultoria - " + id;

  useEffect(() => {
    carregaDados();
  }, []);

  const carregaDados = () => {
    Axios.defaults.withCredentials = false;
    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/buscarTelec", {
      protocolo: id,
    }).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      } else {
        console.log(response);
        setTextSolicitado(response.data[0].assunto);
        if (response.data[0].resposta == null) {
          setText("");
        } else {
          setText(response.data[0].resposta);
        }
        setData(response.data[0].data_hora);
        setArea(response.data[0].area_interesse);
        setFarmaceutico(response.data[0].nome_farmaceutico);
        setSolicitante(response.data[0].nome);
        setStatus(response.data[0].status);
      }
    });
  };

  const responderConsultoria = () => {
    Axios.defaults.withCredentials = false;

    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/responderConsultoria", {
      id: id,
      resposta: text,
    }).then((response) => {
      if (response.data.aviso) {
        alert(response.data.aviso);
      } else {
        alert("Resposta salva!");
        history.push({
          pathname: "/farmaceutico/detalhes",
          state: { detail: id },
        });
      }
    });
  };

  const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
  `;

  return (
    <>
      <SidebarFarmaceutico>
        <div className="main__container" style={{ height: "100%" }}>
          <div
            className="container"
            style={{
              gridTemplateColumns: "1fr 1fr 1fr",
              gridTemplateAreas: "'nav nav nav' 'main main main'",
            }}
          >
            <div>
              <JitsiMeeting
                domain={"devteste.ga"}
                roomName={room}
                getIFrameRef={(node) => {
                  node.style.width = "100%";
                  node.style.height = "100%";
                }}
              />
            </div>
            <div style={{ marginLeft: "1em" }}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "flex-end",
                }}
              >
                <p style={{}}>
                  Solicitante: <b>{solicitante}</b>
                </p>
                <p style={{ marginLeft: "5%" }}>
                  Data: <b>{formatDate(data)}</b>
                </p>
              </div>
              <p className="paragrafo">Pergunta</p>
              <div
                style={{
                  height: "35%",
                  maxHeight: "50%",
                  backgroundColor: "white",
                  padding: "20px",
                }}
              >
                <div style={{ overflowY: "auto", height: "100%" }}>
                  {parse(textSolicitado)}
                </div>
              </div>
              <p className="paragrafo">Editor</p>
              <div className="editor" style={{ height: "40%" }}>
                <CKEditor
                  editor={ClassicEditor}
                  data={text}
                  config={{
                    toolbar: [
                      "heading",
                      "|",
                      "bold",
                      "italic",
                      "|",
                      "undo",
                      "redo",
                      "|",
                      "blockQuote",
                      "|",
                      "link",
                      "bulletedList",
                      "numberedList",
                      "|",
                      "indent",
                      "outdent",
                      "|",
                      "insertTable",
                    ],
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setText(data);
                  }}
                />
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  className="responder"
                  onClick={responderConsultoria}
                  style={{ marginTop: "3.5em" }}
                >
                  Responder
                </button>
              </div>
            </div>
          </div>
        </div>
      </SidebarFarmaceutico>
    </>
  );
};

export default VideoChamadaFarmaceutico;
