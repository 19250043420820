import Axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import SidebarAdministrativo from "../../components/sidebar_administrativo/Sidebar_Administrativo";
import { getCurrentDate } from "../../utils/date";
const DesmarcarTeleA = (props) => {
  const [teleaID, setTeleaID] = useState();
  const [listaTeleAss, setListaTeleAss] = useState([]);
  const [motivo, setMotivo] = useState();
  Axios.defaults.withCredentials = true;
  let history = useHistory();
  const desmarcar = () => {
    Axios.defaults.withCredentials = false;
    Axios.get("https://atendimento.mdhmlg.vsoares.dev.br:3002/loginCPF", {
      withCredentials: true,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }).then((response) => {
      Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/desmarcaTeleAMotivo", {
        id: teleaID,
        responsavel: response.data,
        data: getCurrentDate("-"),
        motivo: motivo,
      }).then((response) => {
        if (response.data.message == "1") {
          Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/deletaAtendimento", {
            id: teleaID,
          }).then((response) => {
            if (response.data.message == "1") {
              Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/desmarcaTeleA", {
                id: teleaID,
              }).then((response) => {
                if (response.data.message == "1") {
                  alert("Teleassistência desmarcada com sucesso!");
                  history.push("/adm");
                } else {
                  alert("Erro ao desmarcar teleassistência!");
                }
              });
            } else {
              alert("Erro ao desmarcar teleassistência!");
            }
          });
        } else {
          alert("Erro ao desmarcar teleassistência!");
        }
      });
    });
  };
  useEffect(() => {
    Axios.get("https://atendimento.mdhmlg.vsoares.dev.br:3002/retornaIDNomeTeleAssistencia").then(
      (response) => {
        if (response.data.message) {
          alert(response.data.message);
        }
        console.log(response.data[0]);
        setListaTeleAss(response.data);
      }
    );
  }, []);
  return (
    <>
      <SidebarAdministrativo>
        <div class="main__container">
          <div>
            <div class="titulo">
              <h3>DESMARCAR TELEASSISTÊNCIA</h3>
            </div>
            <div class="div_teste">
              <div className="div2">
                <div className="item">
                  <p>ID da Assistência: </p>
                  <input
                    value={teleaID}
                    type="text"
                    onChange={(e) => {
                      setTeleaID(e.target.value);
                    }}
                  ></input>
                </div>
                <div className="item">
                  <p>Selecionar Teleassistência: </p>
                  <select
                    value={teleaID}
                    onChange={(e) => {
                      setTeleaID(e.target.value);
                    }}
                  >
                    <option value="0">-</option>
                    {listaTeleAss.map((option) => (
                      <option value={option.id}>{option.nome}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div class="div2">
                <div class="item">
                  <p>Observação / Motivo: </p>
                  <input
                    class="nome"
                    type="text"
                    value={motivo}
                    onChange={(e) => {
                      setMotivo(e.target.value);
                    }}
                  ></input>
                </div>
              </div>
              <div class="div2">
                <div class="item">
                  <p></p>
                  <button onClick={desmarcar}>DESMARCAR</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SidebarAdministrativo>
    </>
  );
};
export default DesmarcarTeleA;
