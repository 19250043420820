import React, { useEffect, useState } from "react";
import { JitsiMeeting } from "@jitsi/react-sdk";
import styled from "styled-components";
import { useDetectAdBlock } from "adblock-detect-react";
import DialogContentText from "@mui/material/DialogContentText";
import Axios from "axios";
import Loading from "../../pages/Loading";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@material-ui/core";

const TelaPaciente = (props) => {
  const [id] = useState(props.match.params.roomID);
  const adBlockDetected = useDetectAdBlock();

  const Container = styled.div`
    height: 100vh;
  `;

  const [mostrarLoading, setMostrarLoading] = useState(true);

  const [codigoValidador, setCodigoValidador] = useState("");
  const [mostrarCodigo, setMostrarCodigo] = useState(true);
  const [btCodigo1, setBtCodigo1] = useState("");
  const [btCodigo2, setBtCodigo2] = useState("");
  const [btCodigo3, setBtCodigo3] = useState("");

  const [mostrarTermos, setmostrarTermos] = useState(false);
  const [mostrarVideo, setMostrarVideo] = useState(false);

  const getCodigoVerificador = () => {
    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/verificaCodigo", {
      headers: {
        "Access-Allow-Control-Origin": "https://atendimento.mdhmlg.vsoares.dev.br",
      },
      prontuario: id,
    }).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      } else {
        exibeCodigo(response.data[0].codigo_verificador);
      }
    });
  };

  const exibeCodigo = (codigo) => {
    setCodigoValidador(codigo);

    const ordem_codigo = Math.floor(Math.random() * (4 - 1)) + 1;

    var random_code_1 = Math.floor(Math.random() * (99 - 11)) + 11;
    var random_code_2 = Math.floor(Math.random() * (99 - 11)) + 11;
    var random_code_3 = Math.floor(Math.random() * (99 - 11)) + 11;

    while (
      random_code_1 === codigoValidador ||
      random_code_2 === codigoValidador ||
      random_code_3 === codigoValidador ||
      random_code_1 === random_code_2 ||
      random_code_2 === random_code_3 ||
      random_code_3 === random_code_1
    ) {
      random_code_1 = Math.floor(Math.random() * (99 - 11)) + 11;
      random_code_2 = Math.floor(Math.random() * (99 - 11)) + 11;
      random_code_3 = Math.floor(Math.random() * (99 - 11)) + 11;
    }

    if (ordem_codigo == 1) {
      setBtCodigo1(codigo);
      setBtCodigo2(random_code_2);
      setBtCodigo3(random_code_3);
      setMostrarLoading(false);
    } else if (ordem_codigo == 2) {
      setBtCodigo1(random_code_1);
      setBtCodigo2(codigo);
      setBtCodigo3(random_code_3);
      setMostrarLoading(false);
    } else {
      setBtCodigo1(random_code_1);
      setBtCodigo2(random_code_2);
      setBtCodigo3(codigo);
      setMostrarLoading(false);
    }
  };

  const verificaCodigo = (codigo) => {
    if (codigoValidador == codigo) {
      setMostrarCodigo(false);
      setmostrarTermos(true);
    } else {
      alert("Código inválido!\nVerifique o código enviado e tente novamente.");
    }
  };

  const aceitarTermos = () => {
    const t = new Date();
    const date = ("0" + t.getDate()).slice(-2);
    const month = ("0" + (t.getMonth() + 1)).slice(-2);
    const year = t.getFullYear();

    const dia = `${date}/${month}/${year}`;
    const hora = `${t.getHours()}:${t.getMinutes()}`;

    Axios.get("https://geolocation-db.com/json/", {
      withCredentials: false,
    }).then((response) => {
      Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/termos", {
        aceitou: "1",
        id_tele_assistencia: id,
        dia: dia,
        hora: hora,
        paisCode: response.data.country_code,
        paisNome: response.data.country_name,
        estado: response.data.state,
        cidade: response.data.city,
        postal: response.data.postal,
        latitude: response.data.latitude,
        longitude: response.data.longitude,
        ip: response.data.IPv4,
        termos:
          "Informamos que a consulta será gravada e ficará armazenada em banco de dados especial.  Dados pessoais, de localização e saúde serão questionados. Os dados fazem parte da ficha de anamnese e prontuário do paciente e poderão ser solicitados a qualquer momento.",
      }).then((response2) => {
        if (response2.data.message) {
          alert(response2.data.message);
        }
      });
    });

    setmostrarTermos(false);
    setMostrarVideo(true);
  };
  const recusarTermos = () => {
    const t = new Date();
    const date = ("0" + t.getDate()).slice(-2);
    const month = ("0" + (t.getMonth() + 1)).slice(-2);
    const year = t.getFullYear();

    const dia = `${date}/${month}/${year}`;
    const hora = `${t.getHours()}:${t.getMinutes()}`;

    Axios.get("https://geolocation-db.com/json/", {
      withCredentials: false,
    }).then((response) => {
      Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/termos", {
        aceitou: "0",
        id_tele_assistencia: id,
        dia: dia,
        hora: hora,
        paisCode: response.data.country_code,
        paisNome: response.data.country_name,
        estado: response.data.state,
        cidade: response.data.city,
        postal: response.data.postal,
        latitude: response.data.latitude,
        longitude: response.data.longitude,
        ip: response.data.IPv4,
        termos:
          "Informamos que a consulta será registrada e armazenada em um banco de dados especializado. Serão solicitadas informações pessoais, de localização e de saúde, que serão incorporadas à sua ficha de anamnese e prontuário médico, podendo ser requisitadas a qualquer momento. Caso a plataforma seja empregada para a prestação de serviços contratados, seus dados poderão ser compartilhados com o prestador de serviço, de maneira anônima, com o único propósito de elaborar relatórios que avaliem a eficácia dos serviços prestados.",
      }).then((response2) => {
        if (response2.data.message) {
          alert(response2.data.message);
        }
      });
    });
    setmostrarTermos(false);
    alert(
      "Você recusou os termos de uso. O sistema será encerrado. Caso queira voltar ao sistema, acesse o link novamente ou recarregue a página."
    );
  };

  function exibeCodigoLoading() {
    if (mostrarCodigo == true && mostrarLoading == false) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (adBlockDetected) {
      alert(
        "AdBlock detectado. Por favor, desative o AdBlock para usar esta plataforma."
      );
    } else {
      getCodigoVerificador();
    }
  }, []);
  return (
    <>
      {mostrarLoading && <Loading />}
      {exibeCodigoLoading() && (
        <Dialog
          open={mostrarCodigo}
          onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              setMostrarCodigo(true);
            }
          }}
        >
          <DialogTitle>Código de verificação</DialogTitle>
          <DialogContent>
            <DialogContentText class="dialogText">
              Clique no código de verificação enviado para o seu WhatsApp:
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                verificaCodigo(btCodigo1);
              }}
              color="secondary"
              variant="contained"
              size="large"
            >
              {btCodigo1}
            </Button>
            <Button
              onClick={() => {
                verificaCodigo(btCodigo2);
              }}
              color="secondary"
              variant="contained"
              size="large"
            >
              {btCodigo2}
            </Button>
            <Button
              onClick={() => {
                verificaCodigo(btCodigo3);
              }}
              color="secondary"
              variant="contained"
              size="large"
            >
              {btCodigo3}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {mostrarTermos && (
        <Dialog
          open={mostrarTermos}
          onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              setmostrarTermos(true);
            }
          }}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Termo de adesão ao serviço</DialogTitle>
          <DialogContent>
            <Typography class="dialogTypograhy">
              Informamos que a consulta será registrada e armazenada em um banco
              de dados especializado. Serão solicitadas informações pessoais, de
              localização e de saúde, que serão incorporadas à sua ficha de
              anamnese e prontuário médico, podendo ser requisitadas a qualquer
              momento. Caso a plataforma seja empregada para a prestação de
              serviços contratados, seus dados poderão ser compartilhados com o
              prestador de serviço, de maneira anônima, com o único propósito de
              elaborar relatórios que avaliem a eficácia dos serviços prestados.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={aceitarTermos}
              color="primary"
              variant="contained"
              size="large"
            >
              Aceitar
            </Button>
            <Button
              onClick={recusarTermos}
              color="secondary"
              variant="contained"
              size="large"
            >
              Recusar
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {mostrarVideo && (
        <Container>
          <JitsiMeeting
            domain={"devteste.ga"}
            roomName={id}
            getIFrameRef={(node) => {
              node.style.width = "100%";
              node.style.height = "100%";
            }}
            onReadyToClose={() => setMostrarVideo(false)}
          />
        </Container>
      )}
    </>
  );
};

export default TelaPaciente;
