import Axios from "axios";
import React, { useState } from "react";
import InputMask from "react-input-mask";
import { useHistory } from "react-router";
import SidebarAdministrativo from "../../components/sidebar_administrativo/Sidebar_Administrativo";

const BuscaTelec = (props) => {
  let history = useHistory();
  const [protocolo, setProtocolo] = useState("");
  const [teste, setTeste] = useState("");

  Axios.defaults.withCredentials = true;

  const buscar = () => {
    Axios.defaults.withCredentials = false;
    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/buscarTelec", {
      protocolo: protocolo,
    }).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      } else {
        setTeste(response.data.message);
        alert(
          "CPF do solicitante: " +
            response.data[0].solicitante_cpf +
            "\n" +
            "CPF do farmacêutico: " +
            response.data[0].farmaceutico_cpf +
            "\n" +
            "Status da solicitação: " +
            response.data[0].status +
            "\n" +
            "Assunto: " +
            response.data[0].area_interesse +
            "\n" +
            "Link: " +
            response.data[0].link +
            "\n"
        );
        history.push({
          pathname: "/adm/detalhes",
          state: { detail: response.data[0].id },
        });
      }
    });
  };

  return (
    <>
      <SidebarAdministrativo>
        <div class="main__container">
          <div class="titulo">
            <h3>BUSCAR TELECONSULTORIAS</h3>
          </div>
          <div class="div_teste">
            <div class="div2">
              <div class="item">
                <p>Teleconsultoria: </p>
                <InputMask
                  class="nome"
                  type="text"
                  value={protocolo}
                  placeholder="Digite o PROTOCOLO da consultoria"
                  onChange={(e) => setProtocolo(e.target.value)}
                ></InputMask>
              </div>
              <div class="item">
                <p></p>
                <button onClick={buscar}>BUSCAR</button>
              </div>
            </div>
          </div>
        </div>
      </SidebarAdministrativo>
    </>
  );
};

export default BuscaTelec;
