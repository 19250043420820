import Axios from "axios";
import React, { useState } from "react";
import SidebarAdministrativo from "../../components/sidebar_administrativo/Sidebar_Administrativo";
import InputMask from "react-input-mask";
import { useHistory } from "react-router";
import { isValidCPF } from "../../utils/valida_cpf.js";
import { getCurrentDate } from "../../utils/date";
import "../Administrativo/Adminstrativo.css";

const CadastroColab = (props) => {
  let history = useHistory();
  const [cpf, setCPF] = useState("");
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [nome, setNome] = useState("");
  const [cep, setCEP] = useState("");
  const [telefone, setTelefone] = useState("");
  const [estado, setEstado] = useState("Acre");
  const [cidade, setCidade] = useState("");
  const [bairro, setBairro] = useState("");
  const [rua, setRua] = useState("");
  const [complemento, setComplemento] = useState("");
  const [dataNasc, setDataNasc] = useState("");
  const [dataCad, setDataCad] = useState(getCurrentDate("-"));
  const [crx, setCRX] = useState("Não Aplica");
  const [crxUf, setCrxUF] = useState("XX");
  const [tipoUsuario, setTipoUsuario] = useState("0");
  const [especialidade, setEspecialidade] = useState("Não Aplica");

  const preencheCEP = () => {
    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/preencheCEP", {
      headers: {
        "Access-Allow-Control-Origin": "https://atendimento.mdhmlg.vsoares.dev.br",
      },
      cep: cep,
    }).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      } else {
        setCEP(response.data.cep);
        setBairro(response.data.bairro);
        setRua(response.data.logradouro);
        setCidade(response.data.localidade);
        setEstado(response.data.uf);
      }
    });
  };

  Axios.defaults.withCredentials = false;

  const register = () => {
    if (
      (email == "") |
      (nome == "") |
      (cep == "") |
      (bairro == "") |
      (cidade == "") |
      (rua == "") |
      (estado == "") |
      (senha == "") |
      (especialidade == "") |
      (cpf == "") |
      (crx == "") |
      (dataNasc == "") |
      (dataCad == "") |
      (telefone == "")
    ) {
      alert(
        "Todos os campos são necessários, verifique, algum pode estar vazio."
      );
    } else {
      if (isValidCPF(cpf)) {
        Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/consultaCPF", { cpf: cpf }).then(
          (response) => {
            if (response.data[0].valida_cpf == "0") {
              Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/consultaEmail", {
                email: email,
              }).then((response) => {
                if (response.data[0].valida_email == "0") {
                  Axios.defaults.withCredentials = false;
                  Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/register", {
                    username: email,
                    password: senha,
                    nome: nome,
                    cpf: cpf,
                    cep: cep,
                    rua: rua,
                    cidade: cidade,
                    bairro: bairro,
                    complemento: complemento,
                    estado: estado,
                    crx: crx,
                    dataNasc: dataNasc,
                    dataCad: getCurrentDate("-"),
                    telefone: telefone,
                    crxuf: crxUf,
                    tipoUsuario: tipoUsuario,
                    especialidade: especialidade,
                  }).then((response) => {
                    if (response.data.message) {
                      alert(
                        "Ocorreu um erro ao cadastrar o colaborador, tente novamente."
                      );
                    } else {
                      alert("O seguinte usuário foi criado: " + nome);
                      history.push("/adm");
                    }
                  });
                } else {
                  alert("E-mail já cadastrado");
                }
              });
            } else {
              alert("CPF inserido já cadastrado!");
            }
          }
        );
      } else {
        alert("CPF inválido!");
      }
    }
  };

  return (
    <>
      <SidebarAdministrativo>
        <div class="main__container">
          <div className="form">
            <div class="titulo">
              <h3>CADASTRAR COLABORADOR</h3>
            </div>
            <div class="div_teste">
              <div class="div2">
                <div class="item">
                  <p>Nome: </p>
                  <input
                    class="nome"
                    type="text"
                    value={nome}
                    onChange={(e) => {
                      setNome(e.target.value);
                    }}
                  ></input>
                </div>
                <div class="item">
                  <p>E-mail:</p>
                  <input
                    type="email"
                    class="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  ></input>
                </div>
              </div>
              <div class="div2">
                <div class="item">
                  <p>Data Nascimento</p>
                  <input
                    type="date"
                    value={dataNasc}
                    onChange={(e) => {
                      setDataNasc(e.target.value);
                    }}
                    min="1900-01-01"
                    max={getCurrentDate("-")}
                  ></input>
                </div>
                <div class="item">
                  <p>CPF: </p>
                  <InputMask
                    mask="999.999.999-99"
                    class="nome"
                    value={cpf}
                    onChange={(e) => {
                      setCPF(e.target.value);
                    }}
                  ></InputMask>
                </div>
              </div>
              <div class="div2">
                <div class="item">
                  <p>Telefone:</p>
                  <InputMask
                    mask="(99) 99999-9999"
                    class="cpf"
                    value={telefone}
                    onChange={(e) => {
                      setTelefone(e.target.value);
                    }}
                  ></InputMask>
                </div>
                <div class="item">
                  <p>CEP:</p>
                  <InputMask
                    mask="99999-999"
                    class="cpf"
                    value={cep}
                    onChange={(e) => {
                      setCEP(e.target.value);
                    }}
                  ></InputMask>
                </div>
                <div className="item">
                  <p></p>
                  <button onClick={preencheCEP}>Preencher endereço</button>
                </div>
                <div class="item">
                  <p>UF:</p>
                  <select
                    id="estado"
                    value={estado}
                    name="estado"
                    onChange={(e) => {
                      setEstado(e.target.value);
                    }}
                  >
                    <option value="AC">Acre</option>
                    <option value="AL">Alagoas</option>
                    <option value="AP">Amapá</option>
                    <option value="AM">Amazonas</option>
                    <option value="BA">Bahia</option>
                    <option value="CE">Ceará</option>
                    <option value="DF">Distrito Federal</option>
                    <option value="ES">Espírito Santo</option>
                    <option value="GO">Goiás</option>
                    <option value="MA">Maranhão</option>
                    <option value="MT">Mato Grosso</option>
                    <option value="MS">Mato Grosso do Sul</option>
                    <option value="MG">Minas Gerais</option>
                    <option value="PA">Pará</option>
                    <option value="PB">Paraíba</option>
                    <option value="PR">Paraná</option>
                    <option value="PE">Pernambuco</option>
                    <option value="PI">Piauí</option>
                    <option value="RJ">Rio de Janeiro</option>
                    <option value="RN">Rio Grande do Norte</option>
                    <option value="RS">Rio Grande do Sul</option>
                    <option value="RO">Rondônia</option>
                    <option value="RR">Roraima</option>
                    <option value="SC">Santa Catarina</option>
                    <option value="SP">São Paulo</option>
                    <option value="SE">Sergipe</option>
                    <option value="TO">Tocantins</option>
                  </select>
                </div>
              </div>
              <div class="div2">
                <div class="item">
                  <p>Cidade</p>
                  <input
                    value={cidade}
                    onChange={(e) => {
                      setCidade(e.target.value);
                    }}
                  ></input>
                </div>
                <div class="item">
                  <p>Bairro: </p>
                  <input
                    value={bairro}
                    onChange={(e) => {
                      setBairro(e.target.value);
                    }}
                  ></input>
                </div>
              </div>
              <div class="div2">
                <div class="item">
                  <p>Rua:</p>
                  <input
                    value={rua}
                    type="text"
                    onChange={(e) => {
                      setRua(e.target.value);
                    }}
                  ></input>
                </div>
                <div class="item">
                  <p>Complemento: </p>
                  <input
                    value={complemento}
                    type="text"
                    onChange={(e) => {
                      setComplemento(e.target.value);
                    }}
                  ></input>
                </div>
              </div>
              <div class="div2">
                <div class="item">
                  <p>Senha:</p>
                  <input
                    value={senha}
                    class="nome"
                    type="password"
                    onChange={(e) => {
                      setSenha(e.target.value);
                    }}
                  ></input>
                </div>
              </div>
              <div class="div2">
                <div class="item">
                  <button onClick={register}>CADASTRAR</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SidebarAdministrativo>
    </>
  );
};

export default CadastroColab;
