import React, { useEffect, useState } from "react";
import SidebarSolicitante from "../../components/sidebar_solicitante/Sidebar_Solicitante";
import { useHistory } from "react-router";
import Axios from "axios";
import { formatDate } from "../../utils/date";

const MinhasTelecSolicitante = (props) => {
  Axios.defaults.withCredentials = true;

  const [lista, setLista] = useState([]);
  //const cpf = props.user.cpf;
  const [cpf, setCPF] = useState("");
  let history = useHistory();

  const cancelar = (id) => {
    Axios.defaults.withCredentials = false;
    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/cancelarTelec", {
      id: id,
    }).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
        carregaLista();
      } else {
        carregaLista();
      }
    });
  };

  const abrir = (id) => {
    history.push({
      pathname: "/solicitante/detalhes",
      state: { detail: id },
    });
  };

  const carregaLista = () => {
    Axios.defaults.withCredentials = false;

    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/minhasTelecAbertas", {
      cpf: cpf,
    }).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      } else {
        console.log(response);
        setLista(response.data);
      }
    });
  };

  useEffect(() => {
    Axios.defaults.withCredentials = false;

    Axios.get("https://atendimento.mdhmlg.vsoares.dev.br:3002/loginCPF", {
      withCredentials: true,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }).then((response) => {
      Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/minhasTelecAbertas", {
        cpf: response.data,
      }).then((response) => {
        if (response.data.message) {
          alert(response.data.message);
        } else {
          console.log(response);
          setLista(response.data);
        }
      });
    });
  }, []);

  return (
    <>
      <SidebarSolicitante>
        <div class="main__container">
          <table class="content-table">
            <thead>
              <tr>
                <th>DATA</th>
                <th>ÁREA INTERESSE</th>
                <th>STATUS</th>
                <th>AÇÃO</th>
              </tr>
            </thead>
            <tbody align="justify">
              {lista.map((item) => (
                <tr>
                  <th>{formatDate(item.data_hora)}</th>
                  <th>{item.area_interesse}</th>
                  <th>{item.status}</th>
                  <th
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      flexDirection: "column",
                      alignContent: "middle",
                    }}
                  >
                    <a
                      style={{ cursor: "pointer" }}
                      id={item.id}
                      onClick={(e) => abrir(e.target.id)}
                    >
                      Abrir
                    </a>
                    <a
                      style={{ cursor: "pointer" }}
                      id={item.id}
                      onClick={(e) => cancelar(e.target.id)}
                    >
                      Cancelar
                    </a>
                  </th>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </SidebarSolicitante>
    </>
  );
};

export default MinhasTelecSolicitante;
