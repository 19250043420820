import Axios from "axios";
import React, { useState, useEffect } from "react";
import InputMask from "react-input-mask";
import { useHistory, useLocation } from "react-router";
import "../Sala/room.css";
import SidebarFarmaceutico from "../../components/sidebar_farmaceutico/Sidebar_Farmaceutico";
import { formatDate } from "../../utils/date";

const Formulario = (props) => {
  let history = useHistory();
  const location = useLocation();
  const [nome, setNome] = useState("");
  const [data_nasc, setDataNasc] = useState("");
  const [cpf, setCpf] = useState("");
  const [sexoBiologico, setSexoBiologico] = useState("");
  const [identidadeGenero, setIdentidadeGenero] = useState("");
  const [altura, setAltura] = useState("");
  const [peso, setPeso] = useState("");
  const [estado_civil, setEstCivil] = useState("");
  const [fone, setFone] = useState("");
  const [cep, setCep] = useState("");
  const [uf, setUf] = useState("");
  const [cidade, setCidade] = useState("");
  const [bairro, setBairro] = useState("");
  const [rua, setRua] = useState("");
  const [complemento, setComplemento] = useState("");
  const [possui_acompanhante, setPossuiAcomp] = useState("");
  const [nome_acompanhante, setAcompanhante] = useState("");
  const [fone_acompanhante, setFoneAcompanhante] = useState("");
  const [obs, setObs] = useState("");
  const [observacaoWhatsApp, setObservacaoWhatsApp] = useState("");
  const [medico_responsavel, setMed] = useState("");
  const [crm_medico, setCrm] = useState("");
  const [fone_medico, setFoneMedico] = useState("");
  const [grau_dependencia, setGrau] = useState("");
  const [internacao_hospitalar, setInternacao] = useState("");
  const [tempo_internacao, setTempoInternacao] = useState("");
  const [servico_emergencia, setEmergencia] = useState("");
  const [tempo_servico, setTempoEmerg] = useState("");
  const [hora_acorda, setHoraAcorda] = useState("");
  const [hora_dorme, setHoraDorme] = useState("");
  const [cafe_manha, setCafeManha] = useState("");
  const [hora_cafe, setHoraCafe] = useState("");
  const [lanche_manha, setLancheManha] = useState("");
  const [hora_lanche_manha, setHoraLancheManha] = useState("");
  const [almoco, setAlmoco] = useState("");
  const [hora_almoco, setHoraAlmoco] = useState("");
  const [lanche_tarde, setLancheTarde] = useState("");
  const [hora_lanche_tarde, setHoraLancheTarde] = useState("");
  const [janta, setJanta] = useState("");
  const [hora_janta, setHoraJanta] = useState("");
  const [ativ_fisica, setAtivFisica] = useState("");
  const [freq_ativ, setFreqAtiv] = useState("");
  const [qual_ativ, setQualAtiv] = useState("");
  const [lazer, setLazer] = useState("");
  const [problemas_saude, setProblemasSaude] = useState("");
  const [queixas, setQueixas] = useState("");
  const [alergias, setAlergias] = useState("");

  //Hepatite B, coqueluche, difteria, tétano, febre amarela, Influenza (gripe), Covid-19, HPV, Rubeola, sarampo, caxumba, pneumococica, Herpes Zóster
  const [vacinaHepatiteB, setVacinaHepatiteB] = useState(false);
  const [vacinaCoqueluche, setVacinaCoqueluche] = useState(false);
  const [vacinaDifteria, setVacinaDifteria] = useState(false);
  const [vacinaTetano, setVacinaTetano] = useState(false);
  const [vacinaFebreAmarela, setVacinaFebreAmarela] = useState(false);
  const [vacinaInfluenza, setVacinaInfluenza] = useState(false);
  const [vacinaCovid, setVacinaCovid] = useState(false);
  const [vacinaHPV, setVacinaHPV] = useState(false);
  const [vacinaRubeola, setVacinaRubeola] = useState(false);
  const [vacinaSarampo, setVacinaSarampo] = useState(false);
  const [vacinaCaxumba, setVacinaCaxumba] = useState(false);
  const [vacinaPneumococica, setVacinaPneumococica] = useState(false);
  const [vacinaHerpes, setVacinaHerpes] = useState(false);

  const [outras_obs, setOutrasObs] = useState("");
  const [resp_medicacao, setRespMedicacao] = useState("");
  const [armazena_medicacao, setArmazenaMedicacao] = useState("");
  const [controla_validade, setControlaValidade] = useState("");
  const [fuma, setFuma] = useState("");
  const [qtd_fuma, setQtdFuma] = useState("");
  const [usa_alcool, setUsaAlcool] = useState("");
  const [exEtilista, setExEtilista] = useState("0");
  const [tipo_alcool, setTipoAlcool] = useState("");
  const [qtd_alcool, setQtdAlcool] = useState("");
  const [freq_alcool, setFreqAlcool] = useState("");
  const [cafe, setTomaCafe] = useState("");
  const [qtd_cafe, setQtdCafe] = useState("");
  const [cha, setTomaCha] = useState("");
  const [qual_cha, setQualCha] = useState("");
  const [qtd_cha, setQtdCha] = useState("");
  const [freq_cha, setFreqCha] = useState("");
  const [dependencia_quimica, setDependQuimica] = useState("");
  const [qual_dependencia, setQualDep] = useState("");
  const [chimarrao, setChimarrao] = useState("");
  const [qtd_chimarrao, setQtdChimarrao] = useState("");
  const [freq_chimarrao, setFreqChimarrao] = useState("");
  const [situacao_tratamento, setSituacaoTratamento] = useState("");
  const [motivo_doenca, setMotivoDoenca] = useState("");
  const [id, setId] = useState(location.state.detail);
  const [listConduta, setListaConduta] = useState([]);
  const [conduta, setConduta] = useState("");
  const [dataConduta, setDataConduta] = useState("");
  const [listaOutrasInformacoes, setListaOutrasInformacoes] = useState([]);
  const [responsavelConduta, setResponsavelConduta] = useState("");
  const [observacaoConduta, setObservacaoConduta] = useState("");
  const [listaMedicamentos, setListaMedicamentos] = useState([]);
  const [listaNutricao, setListaNutricao] = useState([]);
  const [informacaoNutricao, setInformacaoNutricao] = useState("");
  const [informacaoPsicologia, setInformacaoPsicologia] = useState("");
  const [informacaoFarmaceutico, setInformacaoFarmaceutico] = useState("");
  const [listaPsicologia, setListaPsicologia] = useState([]);
  const [listaFarmaceutico, setListaFarmaceutico] = useState([]);
  const [origem, setOrigem] = useState("");

  const [descarteMedicamento, setDescarteMedicamento] = useState("");
  const [descarteMedicamentoOutro, setDescarteMedicamentoOutro] = useState("");

  const [adesaoMorisky, setAdesaoMorisky] = useState("");
  const [razaoAdesaoMorisky, setRazaoAdesaoMorisky] = useState("");
  const [razaoAdesaoMoriskyOutro, setRazaoAdesaoMoriskyOutro] = useState("");

  const [listaEfeitosAdversos, setListaEfeitosAdversos] = useState([]);

  const [medicamentosSemEfetividade, setMedicamentosSemEfetividade] =
    useState("");
  const [medicamentosEfeitosAdversos, setMedicamentosEfeitosAdversos] =
    useState("");

  Axios.defaults.withCredentials = true;

  const retornaCondutas = () => {
    Axios.defaults.withCredentials = false;
    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/retornaConduta", {
      id: location.state.detail,
    }).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      } else {
        console.log(response);
        setListaConduta(response.data);
      }
    });
  };

  const retornaMedicamentos = () => {
    Axios.defaults.withCredentials = false;
    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/retornaMedicamentos", {
      id: location.state.detail,
    }).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      } else {
        console.log(response);
        setListaMedicamentos(response.data);
      }
    });
  };

  const retornaCampoNutricao = () => {
    Axios.defaults.withCredentials = false;
    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/retornaCampoNutricao", {
      id: location.state.detail,
    }).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      } else {
        console.log(response);
        setListaNutricao(response.data);
      }
    });
  };

  const retornaCampoPsicologia = () => {
    Axios.defaults.withCredentials = false;
    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/retornaCampoPsicologia", {
      id: location.state.detail,
    }).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      } else {
        console.log(response);
        setListaPsicologia(response.data);
      }
    });
  };

  const retornaEfeitosAdversos = () => {
    Axios.defaults.withCredentials = false;
    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/retornaEfeitosAdversos", {
      id: location.state.detail,
    }).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      } else {
        console.log(response);
        setListaEfeitosAdversos(response.data);
      }
    });
  };

  const retornaCampoFarmaceutico = () => {
    Axios.defaults.withCredentials = false;
    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/retornaCampoFarmaceutico", {
      id: location.state.detail,
    }).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      } else {
        console.log(response);
        setListaFarmaceutico(response.data);
      }
    });
  };

  const retornaOutrasInformacoes = () => {
    Axios.defaults.withCredentials = false;
    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/retornaOutrasInformacoes", {
      id: location.state.detail,
    }).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      } else {
        console.log(response);
        setListaOutrasInformacoes(response.data);
      }
    });
  };

  useEffect(() => {
    Axios.defaults.withCredentials = false;

    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/carregaPront", {
      id: id,
    }).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      } else {
        setNome(response.data[0].nome);
        setOrigem(response.data[0].origem);
        if (response.data[0].data_nasc != null) {
          let date = new Date(response.data[0].data_nasc);
          setDataNasc(date.toISOString().split("T")[0]);
        }
        setCpf(response.data[0].paciente_cpf);
        setSexoBiologico(response.data[0].sexo_biologico);
        setIdentidadeGenero(response.data[0].identidade_genero);
        setAltura(response.data[0].altura);
        setPeso(response.data[0].peso);
        setEstCivil(response.data[0].estado_civil);
        setFone(response.data[0].fone);
        setCep(response.data[0].cep);
        setUf(response.data[0].estado);
        setCidade(response.data[0].cidade);
        setBairro(response.data[0].bairro);
        setRua(response.data[0].rua);
        setComplemento(response.data[0].complemento);
        setPossuiAcomp(response.data[0].possui_acompanhante);
        setAcompanhante(response.data[0].nome_acompanhante);
        setFoneAcompanhante(response.data[0].telefone_acompanhamento);
        setObs(response.data[0].observacao_um);
        setMed(response.data[0].medico_responsavel);
        setCrm(response.data[0].crm_medico);
        setFoneMedico(response.data[0].telefone_medico);
        setGrau(response.data[0].grau_dependencia);
        setInternacao(response.data[0].internacao_hospital);
        setTempoInternacao(response.data[0].periodo_internacao);
        setEmergencia(response.data[0].servico_emergencia);
        setTempoEmerg(response.data[0].periodo_servemerg);
        setHoraAcorda(response.data[0].horario_acorda);
        setHoraDorme(response.data[0].horario_dorme);
        setCafeManha(response.data[0].cafe_manha);
        setHoraCafe(response.data[0].horario_cafe);
        setLancheManha(response.data[0].lanche_um);
        setHoraLancheManha(response.data[0].horario_lancheum);
        setAlmoco(response.data[0].almoco);
        setHoraAlmoco(response.data[0].horario_almoco);
        setLancheTarde(response.data[0].lanche_dois);
        setHoraLancheTarde(response.data[0].horario_lanchedois);
        setJanta(response.data[0].janta);
        setHoraJanta(response.data[0].horario_janta);
        setAtivFisica(response.data[0].atividade_fisica);
        setFreqAtiv(response.data[0].freq_atv_fisica);
        setQualAtiv(response.data[0].qual_atv);
        setLazer(response.data[0].lazer);
        setProblemasSaude(response.data[0].problemas_saude);
        setQueixas(response.data[0].queixas);
        setAlergias(response.data[0].alergias);
        if (response.data[0].vacinas != null) {
          let vacinasArray = response.data[0].vacinas.split(",");
          if (vacinasArray[0] == "1") {
            setVacinaHepatiteB(true);
          }
          if (vacinasArray[1] == "1") {
            setVacinaCoqueluche(true);
          }
          if (vacinasArray[2] == "1") {
            setVacinaDifteria(true);
          }
          if (vacinasArray[3] == "1") {
            setVacinaTetano(true);
          }
          if (vacinasArray[4] == "1") {
            setVacinaFebreAmarela(true);
          }
          if (vacinasArray[5] == "1") {
            setVacinaInfluenza(true);
          }
          if (vacinasArray[6] == "1") {
            setVacinaCovid(true);
          }
          if (vacinasArray[7] == "1") {
            setVacinaHPV(true);
          }
          if (vacinasArray[8] == "1") {
            setVacinaRubeola(true);
          }
          if (vacinasArray[9] == "1") {
            setVacinaSarampo(true);
          }
          if (vacinasArray[10] == "1") {
            setVacinaCaxumba(true);
          }
          if (vacinasArray[11] == "1") {
            setVacinaPneumococica(true);
          }
          if (vacinasArray[12] == "1") {
            setVacinaHerpes(true);
          }
        }
        setOutrasObs(response.data[0].outras_obs);
        setRespMedicacao(response.data[0].responsavel_adm);
        setArmazenaMedicacao(response.data[0].onde_armazena);
        setControlaValidade(response.data[0].controla_validade);
        setFuma(response.data[0].fuma);
        setQtdFuma(response.data[0].qtd_fuma);
        setUsaAlcool(response.data[0].alcool);
        setExEtilista(response.data[0].ex_etilista);
        setTipoAlcool(response.data[0].tipo_alcool);
        setQtdAlcool(response.data[0].qtd_alcool);
        setFreqAlcool(response.data[0].freq_alcool);
        setTomaCafe(response.data[0].cafe);
        setQtdCafe(response.data[0].qtd_cafe);
        setObservacaoWhatsApp(response.data[0].observacao_whatsapp);
        setTomaCha(response.data[0].cha);
        setQualCha(response.data[0].qual_cha);
        setQtdCha(response.data[0].qtd_cha);
        setFreqCha(response.data[0].freq_cha);
        setDependQuimica(response.data[0].possui_dependencia);
        setQualDep(response.data[0].qual_dependencia);
        setChimarrao(response.data[0].chimarrao);
        setQtdChimarrao(response.data[0].qtd_chimarrao);
        setFreqChimarrao(response.data[0].freq_chimarrao);
        setSituacaoTratamento(response.data[0].situacao_tratamento);
        setMotivoDoenca(response.data[0].motivo_doenca_relatado);
        setMedicamentosEfeitosAdversos(
          response.data[0].medicamentos_efeitos_adversos
        );
        setMedicamentosSemEfetividade(
          response.data[0].medicamentos_sem_efetividade
        );
        setAdesaoMorisky(response.data[0].adesao_morisky);
        setRazaoAdesaoMorisky(response.data[0].razao_adesao_morisky);
        setRazaoAdesaoMoriskyOutro(response.data[0].razao_adesao_morisky_outro);
        setDescarteMedicamento(response.data[0].descarte_medicamento);
        setDescarteMedicamentoOutro(
          response.data[0].descarte_medicamento_outro
        );
      }
      console.log(response);
    });
    retornaCondutas();
    retornaOutrasInformacoes();
    retornaMedicamentos();
    retornaCampoNutricao();
    retornaCampoPsicologia();
    retornaCampoFarmaceutico();
    retornaEfeitosAdversos();
  }, []);

  return (
    <SidebarFarmaceutico>
      <div style={{ overflow: "auto" }}>
        <div className="div_teste">
          <div className="div2">
            <div className="item">
              <p
                style={{
                  textAlign: "center",
                  verticalAlign: "center",
                  justifyContent: "center",
                  fontSize: "20px",
                }}
              >
                <b>FORMULÁRIO DE ACOMPANHAMENTO FARMACOTERAPÊUTICO</b>
              </p>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p>Nome: </p>
              <input
                readonly="readonly"
                value={nome}
                onChange={(e) => {
                  setNome(e.target.value);
                }}
              ></input>
            </div>
            <div className="item">
              <p>Origem: </p>
              <input
                readonly="readonly"
                value={origem}
                onChange={(e) => {
                  setOrigem(e.target.value);
                }}
              ></input>
            </div>
            <div className="item">
              <p>Data Nasc.: </p>
              <input
                readonly="readonly"
                type="date"
                date={data_nasc}
                value={data_nasc}
                onChange={(e) => {
                  setDataNasc(e.target.value);
                }}
              ></input>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p>CPF: </p>
              <InputMask
                readonly="readonly"
                mask="999.999.999-99"
                value={cpf}
                onChange={(e) => {
                  setCpf(e.target.value);
                }}
              ></InputMask>
            </div>
            <div className="item">
              <p>Sexo biológico: </p>
              <select readonly="readonly" value={sexoBiologico}>
                <option value="M">Masculino</option>
                <option value="F">Feminino</option>
              </select>
              <p>Identidade de gênero: </p>
              <select readonly="readonly" value={identidadeGenero}>
                <option value="C">Cisgênero</option>
                <option value="T">Transgênero</option>
                <option value="N">Não-binário</option>
              </select>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p>Altura (cm): </p>
              <input
                readonly="readonly"
                type="number"
                value={altura}
                onChange={(e) => {
                  setAltura(e.target.value);
                }}
              ></input>
            </div>
            <div className="item">
              <p>Peso (kg): </p>
              <input
                readonly="readonly"
                type="number"
                value={peso}
                onChange={(e) => {
                  setPeso(e.target.value);
                }}
              ></input>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p>Estado Civil: </p>
              <select
                value={estado_civil}
                onChange={(e) => {
                  setEstCivil(e.target.value);
                }}
              >
                <option>Não Declarado</option>
                <option>Solteiro</option>
                <option>Casado</option>
                <option>Viúvo</option>
                <option>Namorando</option>
              </select>
            </div>
            <div className="item">
              <p>Fone: </p>
              <InputMask
                readonly="readonly"
                mask="(99) 99999-9999"
                value={fone}
                onChange={(e) => {
                  setFone(e.target.value);
                }}
              ></InputMask>
            </div>
            <div className="item">
              <p>CEP: </p>
              <InputMask
                readonly="readonly "
                mask="99999-999"
                value={cep}
                onChange={(e) => {
                  setCep(e.target.value);
                }}
              ></InputMask>
            </div>
            <div className="item">
              <p>UF: </p>
              <select
                value={uf}
                onChange={(e) => {
                  setUf(e.target.value);
                }}
              >
                <option value="AC">AC</option>
                <option value="AL">AL</option>
                <option value="AP">AP</option>
                <option value="AM">AM</option>
                <option value="BA">BA</option>
                <option value="CE">CE</option>
                <option value="DF">DF</option>
                <option value="ES">ES</option>
                <option value="GO">GO</option>
                <option value="MA">MA</option>
                <option value="MT">MT</option>
                <option value="MS">MS</option>
                <option value="MG">MG</option>
                <option value="PA">PA</option>
                <option value="PB">PB</option>
                <option value="PR">PR</option>
                <option value="PE">PE</option>
                <option value="PI">PI</option>
                <option value="RJ">RJ</option>
                <option value="RN">RN</option>
                <option value="RS">RS</option>
                <option value="RO">RO</option>
                <option value="RR">RR</option>
                <option value="SC">SC</option>
                <option value="SP">SP</option>
                <option value="SE">SE</option>
                <option value="TO">TO</option>
              </select>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p>Cidade: </p>
              <input
                readonly="readonly"
                value={cidade}
                onChange={(e) => {
                  setCidade(e.target.value);
                }}
              ></input>
            </div>
            <div className="item">
              <p>Bairro: </p>
              <input
                readonly="readonly"
                value={bairro}
                onChange={(e) => {
                  setBairro(e.target.value);
                }}
              ></input>
            </div>
            <div className="item">
              <p>Logradouro: </p>
              <input
                readonly="readonly"
                value={rua}
                onChange={(e) => {
                  setRua(e.target.value);
                }}
              ></input>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p>Possui acompanhante: </p>
              <select
                value={possui_acompanhante}
                onChange={(e) => {
                  setPossuiAcomp(e.target.value);
                }}
              >
                <option>Sim</option>
                <option>Não</option>
              </select>
            </div>
            <div className="item">
              <p>Nome do Acompanhante: </p>
              <input
                readonly="readonly"
                value={nome_acompanhante}
                onChange={(e) => {
                  setAcompanhante(e.target.value);
                }}
              ></input>
            </div>
            <div className="item">
              <p>Fone do Acompanhante: </p>
              <InputMask
                readonly="readonly"
                mask="(99) 99999-9999"
                value={fone_acompanhante}
                onChange={(e) => {
                  setFoneAcompanhante(e.target.value);
                }}
              ></InputMask>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p>Observações: </p>
              <textarea
                placeholder="Descrever sobre, por exemplo:  rotina de médicos/especialidades, diurese, evacuação, quantidade de ingesta de água, se dorme bem ou com auxílio de medicamentos..."
                readonly="readonly"
                class="textoGrande"
                value={obs}
                onChange={(e) => {
                  setObs(e.target.value);
                }}
              ></textarea>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p>Problemas de saúde</p>
              <textarea
                readonly="readonly"
                class="textoGrande"
                value={problemas_saude}
                onChange={(e) => {
                  setProblemasSaude(e.target.value);
                }}
              ></textarea>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p>Evoluções:</p>
              <textarea
                readonly="readonly"
                class="textoGrande"
                value={outras_obs}
                onChange={(e) => {
                  setOutrasObs(e.target.value);
                }}
              ></textarea>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p>Outras informações de suporte via WhatsApp Livre:</p>
              <textarea
                readonly="readonly"
                class="textoGrande"
                value={observacaoWhatsApp}
              ></textarea>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p>Médico responsável: </p>
              <input
                readonly="readonly"
                value={medico_responsavel}
                onChange={(e) => {
                  setMed(e.target.value);
                }}
              ></input>
            </div>
            <div className="item">
              <p>CRM: </p>
              <input
                readonly="readonly"
                value={crm_medico}
                onChange={(e) => {
                  setCrm(e.target.value);
                }}
              ></input>
            </div>
            <div className="item">
              <p>Fone do Médico: </p>
              <InputMask
                readonly="readonly"
                mask="(99) 99999-9999"
                value={fone_medico}
                onChange={(e) => {
                  setFoneMedico(e.target.value);
                }}
              ></InputMask>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p
                style={{
                  textAlign: "center",
                  background: "green",
                  verticalAlign: "center",
                }}
              ></p>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p>Grau de dependência</p>
              <select
                value={grau_dependencia}
                onChange={(e) => {
                  setGrau(e.target.value);
                }}
              >
                <option>Não tem</option>
                <option>Paciente cadeirante</option>
                <option>Paciente acamado</option>
              </select>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p
                style={{
                  textAlign: "center",
                  background: "yellow",
                  verticalAlign: "center",
                }}
              ></p>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p>Esteve internado em Hospital?</p>
              <select
                value={internacao_hospitalar}
                onChange={(e) => {
                  setInternacao(e.target.value);
                }}
              >
                <option value=""></option>
                <option value="1">Sim</option>
                <option value="2">Não</option>
              </select>
            </div>
            <div className="item">
              <p>Período</p>
              <select
                value={tempo_internacao}
                onChange={(e) => {
                  setTempoInternacao(e.target.value);
                }}
              >
                <option value=""></option>
                <option value="1">Nos últimos 30 dias</option>
                <option value="2">Nos últimos 90 dias</option>
                <option value="3">No último ano</option>
                <option value="4">Não se aplica</option>
              </select>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p>Esteve em serviço de emergência?</p>
              <select
                value={servico_emergencia}
                onChange={(e) => {
                  setEmergencia(e.target.value);
                }}
              >
                <option value=""></option>
                <option value="1">Sim</option>
                <option value="2">Não</option>
              </select>
            </div>
            <div className="item">
              <p>Período</p>
              <select
                value={tempo_servico}
                onChange={(e) => {
                  setTempoEmerg(e.target.value);
                }}
              >
                <option value=""></option>
                <option value="1">Nos últimos 30 dias</option>
                <option value="2">Nos últimos 90 dias</option>
                <option value="3">No último ano</option>
                <option value="4">Não se aplica</option>
              </select>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p
                style={{
                  textAlign: "center",
                  background: "black",
                  verticalAlign: "center",
                }}
              ></p>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p>Horário que acorda</p>
              <input
                readonly="readonly"
                type="time"
                value={hora_acorda}
                onChange={(e) => {
                  setHoraAcorda(e.target.value);
                }}
              ></input>
            </div>
            <div className="item">
              <p>Horário que dorme</p>
              <input
                readonly="readonly"
                value={hora_dorme}
                onChange={(e) => {
                  setHoraDorme(e.target.value);
                }}
                type="time"
              ></input>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p>Café da manhã</p>
              <input
                readonly="readonly"
                value={cafe_manha}
                onChange={(e) => {
                  setCafeManha(e.target.value);
                }}
              ></input>
            </div>
            <div className="item">
              <p>Horário</p>
              <input
                readonly="readonly"
                value={hora_cafe}
                onChange={(e) => {
                  setHoraCafe(e.target.value);
                }}
                type="time"
              ></input>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p>Lanche</p>
              <input
                readonly="readonly"
                value={lanche_manha}
                onChange={(e) => {
                  setLancheManha(e.target.value);
                }}
              ></input>
            </div>
            <div className="item">
              <p>Horário</p>
              <input
                readonly="readonly"
                value={hora_lanche_manha}
                onChange={(e) => {
                  setHoraLancheManha(e.target.value);
                }}
                type="time"
              ></input>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p>Almoço</p>
              <input
                readonly="readonly"
                value={almoco}
                onChange={(e) => {
                  setAlmoco(e.target.value);
                }}
              ></input>
            </div>
            <div className="item">
              <p>Horário</p>
              <input
                readonly="readonly"
                value={hora_almoco}
                onChange={(e) => {
                  setHoraAlmoco(e.target.value);
                }}
                type="time"
              ></input>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p>Lanche</p>
              <input
                readonly="readonly"
                value={lanche_tarde}
                onChange={(e) => {
                  setLancheTarde(e.target.value);
                }}
              ></input>
            </div>
            <div className="item">
              <p>Horário</p>
              <input
                readonly="readonly"
                value={hora_lanche_tarde}
                onChange={(e) => {
                  setHoraLancheTarde(e.target.value);
                }}
                type="time"
              ></input>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p>Janta</p>
              <input
                readonly="readonly"
                value={janta}
                onChange={(e) => {
                  setJanta(e.target.value);
                }}
              ></input>
            </div>
            <div className="item">
              <p>Horário</p>
              <input
                readonly="readonly"
                value={hora_janta}
                onChange={(e) => {
                  setHoraJanta(e.target.value);
                }}
                type="time"
              ></input>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p>Atividade Física</p>
              <select
                value={ativ_fisica}
                onChange={(e) => {
                  setAtivFisica(e.target.value);
                }}
              >
                <option>Sim</option>
                <option>Não</option>
              </select>
            </div>
            <div className="item">
              <p>Frequência</p>
              <select
                value={freq_ativ}
                onChange={(e) => {
                  setFreqAtiv(e.target.value);
                }}
              >
                <option>Não pratica</option>
                <option>De 1 a 2 dias</option>
                <option>De 3 a 6 dias</option>
                <option>Todos os dias</option>
              </select>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p>Qual atividade</p>
              <input
                readonly="readonly"
                value={qual_ativ}
                onChange={(e) => {
                  setQualAtiv(e.target.value);
                }}
              ></input>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p>Lazer</p>
              <input
                readonly="readonly"
                value={lazer}
                onChange={(e) => {
                  setLazer(e.target.value);
                }}
              ></input>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p
                style={{
                  textAlign: "center",
                  background: "red",
                  verticalAlign: "center",
                }}
              ></p>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p>Queixas</p>
              <textarea
                readonly="readonly"
                class="textoGrande"
                value={queixas}
                onChange={(e) => {
                  setQueixas(e.target.value);
                }}
              ></textarea>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p>Alergias</p>
              <textarea
                readonly="readonly"
                class="textoGrande"
                value={alergias}
                onChange={(e) => {
                  setAlergias(e.target.value);
                }}
              ></textarea>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p>Vacinas</p>
              <div className="vacinas">
                <label className="vacinas">
                  Caxumba
                  <input
                    className="radio"
                    type="radio"
                    checked={vacinaCaxumba}
                    onChange={(e) => {
                      setVacinaCaxumba(!vacinaCaxumba);
                    }}
                  />{" "}
                </label>
                <label className="vacinas">
                  Coqueluche
                  <input
                    className="radio"
                    type="radio"
                    checked={vacinaCoqueluche}
                    onChange={(e) => {
                      setVacinaCoqueluche(!vacinaCoqueluche);
                    }}
                  />{" "}
                </label>
                <label className="vacinas">
                  Covid-19
                  <input
                    className="radio"
                    type="radio"
                    checked={vacinaCovid}
                    onChange={(e) => {
                      setVacinaCovid(!vacinaCovid);
                    }}
                  />{" "}
                </label>
                <label className="vacinas">
                  Difteria
                  <input
                    className="radio"
                    type="radio"
                    checked={vacinaDifteria}
                    onChange={(e) => {
                      setVacinaDifteria(!vacinaDifteria);
                    }}
                  />{" "}
                </label>

                <label className="vacinas">
                  Febre amarela
                  <input
                    className="radio"
                    type="radio"
                    checked={vacinaFebreAmarela}
                    onChange={(e) => {
                      setVacinaFebreAmarela(!vacinaFebreAmarela);
                    }}
                  />{" "}
                </label>
                <label className="vacinas">
                  Herpes Zóster
                  <input
                    className="radio"
                    type="radio"
                    checked={vacinaHerpes}
                    onChange={(e) => {
                      setVacinaHerpes(!vacinaHerpes);
                    }}
                  />{" "}
                </label>
                <label className="vacinas">
                  Hepatite B
                  <input
                    className="radio"
                    type="radio"
                    checked={vacinaHepatiteB}
                    onChange={(e) => {
                      setVacinaHepatiteB(!vacinaHepatiteB);
                    }}
                  />{" "}
                </label>
                <label className="vacinas">
                  HPV
                  <input
                    className="radio"
                    type="radio"
                    checked={vacinaHPV}
                    onChange={(e) => {
                      setVacinaHPV(!vacinaHPV);
                    }}
                  />{" "}
                </label>

                <label className="vacinas">
                  Influenza (gripe)
                  <input
                    className="radio"
                    type="radio"
                    checked={vacinaInfluenza}
                    onChange={(e) => {
                      setVacinaInfluenza(!vacinaInfluenza);
                    }}
                  />{" "}
                </label>
                <label className="vacinas">
                  Pneumococica
                  <input
                    className="radio"
                    type="radio"
                    checked={vacinaPneumococica}
                    onChange={(e) => {
                      setVacinaPneumococica(!vacinaPneumococica);
                    }}
                  />{" "}
                </label>
                <label className="vacinas">
                  Rubéola
                  <input
                    className="radio"
                    type="radio"
                    checked={vacinaRubeola}
                    onChange={(e) => {
                      setVacinaRubeola(!vacinaRubeola);
                    }}
                  />{" "}
                </label>
                <label className="vacinas">
                  Sarampo
                  <input
                    className="radio"
                    type="radio"
                    checked={vacinaSarampo}
                    onChange={(e) => {
                      setVacinaSarampo(!vacinaSarampo);
                    }}
                  />{" "}
                </label>
                <label className="vacinas">
                  Tétano
                  <input
                    className="radio"
                    type="radio"
                    checked={vacinaTetano}
                    onChange={(e) => {
                      setVacinaTetano(!vacinaTetano);
                    }}
                  />{" "}
                </label>
              </div>
            </div>
          </div>
          <div className="div2">
            <div
              className="item"
              style={{ overflowY: "auto", maxHeight: "400px" }}
            >
              <table className="content-table" style={{ margin: "0px" }}>
                <thead>
                  <tr>
                    <th>Data</th>
                    <th>Medicamento</th>
                    <th>Dose</th>
                    <th>Posologia</th>
                    <th>Tempo de Uso</th>
                    <th>Mesmo Lab.?</th>
                    <th>Mesmo horário?</th>
                    <th>Motivo Uso</th>
                    <th>Quem indicou?</th>
                    <th>Necessidade</th>
                    <th>Efetividade</th>
                    <th>Segurança</th>
                  </tr>
                </thead>
                <tbody align="justify">
                  {listaMedicamentos.map((item) => (
                    <tr>
                      <th>{formatDate(item.data)}</th>
                      <th>{item.medicamento}</th>
                      <th>{item.dose}</th>
                      <th>{item.posologia}</th>
                      <th>{item.tempo_uso}</th>
                      <th>{item.mesmo_lab}</th>
                      <th>{item.mesmo_horario}</th>
                      <th>{item.motivo_uso}</th>
                      <th>{item.quem_indicou}</th>
                      <th>{item.necessario}</th>
                      <th>{item.efetividade}</th>
                      <th>{item.seguranca}</th>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p>
                Medicamento(s)que utilizou e causou efeitos adversos( não
                tolerou)
              </p>
              <input
                readonly="readonly"
                value={medicamentosEfeitosAdversos}
                onChange={(e) => {
                  setMedicamentosEfeitosAdversos(e.target.value);
                }}
              ></input>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p>Medicamento(s) que tomou e não foram efetivos</p>
              <input
                readonly="readonly"
                value={medicamentosSemEfetividade}
                onChange={(e) => {
                  setMedicamentosSemEfetividade(e.target.value);
                }}
              ></input>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <h3>Segurança</h3>
            </div>
          </div>
          <div className="div2">
            <div
              className="item"
              style={{ overflowY: "auto", maxHeight: "400px" }}
            >
              <table className="content-table" style={{ margin: "0px" }}>
                <thead>
                  <tr>
                    <th>Data</th>
                    <th>Medicamento</th>
                    <th>Efeito adverso</th>
                  </tr>
                </thead>
                <tbody align="justify">
                  {listaEfeitosAdversos.map((item) => (
                    <tr>
                      <th>{formatDate(item.data)}</th>
                      <th>{item.medicamento}</th>
                      <th>{item.efeito}</th>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p
                style={{
                  textAlign: "center",
                  background: "blue",
                  verticalAlign: "center",
                }}
              ></p>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p>Responsável pela adm. da medicação</p>
              <select
                value={resp_medicacao}
                onChange={(e) => {
                  setRespMedicacao(e.target.value);
                }}
              >
                <option>Paciente</option>
                <option>Outro</option>
              </select>
            </div>
            <div className="item">
              <p>Onde armazena a medicação</p>
              <input
                readonly="readonly"
                value={armazena_medicacao}
                onChange={(e) => {
                  setArmazenaMedicacao(e.target.value);
                }}
              ></input>
            </div>
            <div className="item">
              <p>Controla o prazo de validade</p>
              <select
                value={controla_validade}
                onChange={(e) => {
                  setControlaValidade(e.target.value);
                }}
              >
                <option>Sim</option>
                <option>Não</option>
              </select>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p>Descarte de medicamentos</p>
              <select
                value={controla_validade}
                onChange={(e) => {
                  setControlaValidade(e.target.value);
                }}
              >
                <option value=""></option>
                <option value="1">Lixo comum</option>
                <option value="2">Vaso sanitário</option>
                <option value="3">Queima em casa </option>
                <option value="4">
                  Entrega para farmácia/unidade de saúde/hospital
                </option>
              </select>
            </div>
            <div className="item">
              <p>Outra opção de descarte</p>
              <input
                value={descarteMedicamentoOutro}
                onChange={(e) => {
                  setDescarteMedicamentoOutro(e.target.value);
                }}
              ></input>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <h2>Adesão</h2>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p>Escala Morisky</p>
              <textarea
                class="textoGrande"
                value={adesaoMorisky}
                onChange={(e) => {
                  setAdesaoMorisky(e.target.value);
                }}
              ></textarea>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p>Identifique o motivo:</p>
              <select
                value={razaoAdesaoMorisky}
                onChange={(e) => {
                  setRazaoAdesaoMorisky(e.target.value);
                }}
              >
                <option value=""></option>
                <option value="1">
                  Não tem como adquirir o medicamento (problema financeiro)
                </option>
                <option value="2">Não entende a doença</option>
                <option value="3">Esquece de tomar</option>
                <option value="4">Devido efeitos adversos</option>
                <option value="5">Não tem receita</option>
              </select>
            </div>
            <div className="item">
              <p>Outro motivo:</p>
              <input
                readOnly="readonly"
                value={razaoAdesaoMoriskyOutro}
                onChange={(e) => {
                  setRazaoAdesaoMoriskyOutro(e.target.value);
                }}
              ></input>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p
                style={{
                  textAlign: "center",
                  background: "yellow",
                  verticalAlign: "center",
                }}
              ></p>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p>Fuma</p>
              <select
                value={fuma}
                onChange={(e) => {
                  setFuma(e.target.value);
                }}
              >
                <option>Sim</option>
                <option>Não</option>
              </select>
            </div>
            <div className="item">
              <p>Quantidade ao dia</p>
              <input
                readonly="readonly"
                value={qtd_fuma}
                onChange={(e) => {
                  setQtdFuma(e.target.value);
                }}
              ></input>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p>Álcool</p>
              <select
                value={usa_alcool}
                onChange={(e) => {
                  setUsaAlcool(e.target.value);
                }}
              >
                <option>Sim</option>
                <option>Não</option>
              </select>
            </div>
            <div className="item">
              <p>Ex-etilista</p>
              <select
                readonly="readonly"
                value={exEtilista}
                onChange={(e) => {
                  setExEtilista(e.target.value);
                }}
              >
                <option value="1">Sim</option>
                <option value="0">Não</option>
              </select>
            </div>
            <div className="item">
              <p>Tipo bebida</p>
              <input
                readonly="readonly"
                value={tipo_alcool}
                onChange={(e) => {
                  setTipoAlcool(e.target.value);
                }}
              ></input>
            </div>
            <div className="item">
              <p>Quantidade</p>
              <input
                readonly="readonly"
                value={qtd_alcool}
                onChange={(e) => {
                  setQtdAlcool(e.target.value);
                }}
              ></input>
            </div>
            <div className="item">
              <p>Frequência</p>
              <select
                value={freq_alcool}
                onChange={(e) => {
                  setFreqAlcool(e.target.value);
                }}
              >
                <option>Não utiliza</option>
                <option>De 1 a 2 dias</option>
                <option>De 3 a 6 dias</option>
                <option>Todos os dias</option>
              </select>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p>Café</p>
              <select
                value={cafe}
                onChange={(e) => {
                  setTomaCafe(e.target.value);
                }}
              >
                <option>Sim</option>
                <option>Não</option>
              </select>
            </div>
            <div className="item">
              <p>Quantidade/Dia/Xícara</p>
              <input
                value={qtd_cafe}
                onChange={(e) => {
                  setQtdCafe(e.target.value);
                }}
              ></input>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p>Chá</p>
              <select
                value={cha}
                onChange={(e) => {
                  setTomaCha(e.target.value);
                }}
              >
                <option>Sim</option>
                <option>Não</option>
              </select>
            </div>
            <div className="item">
              <p>Qual</p>
              <input
                value={qual_cha}
                onChange={(e) => {
                  setQualCha(e.target.value);
                }}
              ></input>
            </div>
            <div className="item">
              <p>Quantidade</p>
              <input
                value={qtd_cha}
                onChange={(e) => {
                  setQtdCha(e.target.value);
                }}
              ></input>
            </div>
            <div className="item">
              <p>Frequência</p>
              <select
                value={freq_cha}
                onChange={(e) => {
                  setFreqCha(e.target.value);
                }}
              >
                <option>Não utiliza</option>
                <option>De 1 a 2 dias</option>
                <option>De 3 a 6 dias</option>
                <option>Todos os dias</option>
              </select>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p>Possui alguma dependência química?</p>
              <select
                value={dependencia_quimica}
                onChange={(e) => {
                  setDependQuimica(e.target.value);
                }}
              >
                <option>Sim</option>
                <option>Não</option>
              </select>
            </div>
            <div className="item">
              <p>Se sim, qual?</p>
              <input
                value={qual_dependencia}
                onChange={(e) => {
                  setQualDep(e.target.value);
                }}
              ></input>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p>Chimarrão</p>
              <select
                value={chimarrao}
                onChange={(e) => {
                  setChimarrao(e.target.value);
                }}
              >
                <option>Sim</option>
                <option>Não</option>
              </select>
            </div>
            <div className="item">
              <p>Quantidade</p>
              <input
                value={qtd_chimarrao}
                onChange={(e) => {
                  setQtdChimarrao(e.target.value);
                }}
              ></input>
            </div>
            <div className="item">
              <p>Frequência</p>
              <select
                value={freq_chimarrao}
                onChange={(e) => {
                  setFreqChimarrao(e.target.value);
                }}
              >
                <option>Eventualmente</option>
                <option>De 1 a 2 dias</option>
                <option>De 3 a 6 dias</option>
                <option>Todos os dias</option>
              </select>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p>Como está o seu tratamento?</p>
              <input
                readonly="readonly"
                value={situacao_tratamento}
                onChange={(e) => {
                  setSituacaoTratamento(e.target.value);
                }}
              ></input>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p>Por quê você acha que tem a doença?</p>
              <input
                readonly="readonly"
                value={motivo_doenca}
                onChange={(e) => {
                  setMotivoDoenca(e.target.value);
                }}
              ></input>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <table class="content-table">
                <thead>
                  <tr>
                    <th>Data</th>
                    <th>Conduta</th>
                    <th>Observações</th>
                    <th>Farm. Responsável</th>
                  </tr>
                </thead>
                <tbody align="justify">
                  {listConduta.map((item) => (
                    <tr>
                      <th>{formatDate(item.data)}</th>
                      <th>{item.conduta}</th>
                      <th>{item.observacao}</th>
                      <th>{item.responsavel}</th>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="div2">
            <div className="item">
              <p
                style={{
                  textAlign: "center",
                  background: "green",
                  verticalAlign: "center",
                }}
              ></p>
            </div>
          </div>

          <div className="div2">
            <div
              className="item"
              style={{ overflowY: "auto", maxHeight: "400px" }}
            >
              <table className="content-table" style={{ margin: "0px" }}>
                <thead>
                  <tr>
                    <th>Data</th>
                    <th>Informação</th>
                    <th>Farm. Responsável</th>
                  </tr>
                </thead>
                <tbody align="justify">
                  {listaOutrasInformacoes.map((item) => (
                    <tr>
                      <th>{formatDate(item.data)}</th>
                      <th>{item.informacao}</th>
                      <th>{item.responsavel}</th>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="div2">
            <div className="item">
              <p
                style={{
                  textAlign: "center",
                  background: "blue",
                  verticalAlign: "center",
                }}
              ></p>
            </div>
          </div>

          <div className="div2">
            <div className="item">
              <p
                style={{
                  textAlign: "center",
                  background: "blue",
                  verticalAlign: "center",
                }}
              ></p>
            </div>
          </div>

          <div className="div2">
            <div className="item">
              <p
                style={{
                  textAlign: "center",
                  verticalAlign: "center",
                  justifyContent: "center",
                  fontSize: "20px",
                }}
              >
                <b>CAMPO EXCLUSIVO DO CUIDADO NUTRICIONAL</b>
              </p>
            </div>
          </div>

          <div className="div2">
            <div
              className="item"
              style={{ overflowY: "auto", maxHeight: "400px" }}
            >
              <table className="content-table" style={{ margin: "0px" }}>
                <thead>
                  <tr>
                    <th>Data</th>
                    <th>Informação</th>
                    <th>Nutri. Responsável</th>
                  </tr>
                </thead>
                <tbody align="justify">
                  {listaNutricao.map((item) => (
                    <tr>
                      <th>{formatDate(item.data)}</th>
                      <th>{item.informacao}</th>
                      <th>{item.responsavel}</th>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="div2">
            <div className="item">
              <p
                style={{
                  textAlign: "center",
                  background: "red",
                  verticalAlign: "center",
                }}
              ></p>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p
                style={{
                  textAlign: "center",
                  verticalAlign: "center",
                  justifyContent: "center",
                  fontSize: "20px",
                }}
              >
                <b>CAMPO EXCLUSIVO DO CUIDADO PSICOLÓGICO</b>
              </p>
            </div>
          </div>
          <div className="div2">
            <div
              className="item"
              style={{ overflowY: "auto", maxHeight: "400px" }}
            >
              <table className="content-table" style={{ margin: "0px" }}>
                <thead>
                  <tr>
                    <th>Data</th>
                    <th>Informação</th>
                    <th>Psi. Responsável</th>
                  </tr>
                </thead>
                <tbody align="justify">
                  {listaPsicologia.map((item) => (
                    <tr>
                      <th>{formatDate(item.data)}</th>
                      <th>{item.informacao}</th>
                      <th>{item.responsavel}</th>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="div2">
            <div className="item">
              <p
                style={{
                  textAlign: "center",
                  background: "yellow",
                  verticalAlign: "center",
                }}
              ></p>
            </div>
          </div>
          <div className="div2">
            <div className="item">
              <p
                style={{
                  textAlign: "center",
                  verticalAlign: "center",
                  justifyContent: "center",
                  fontSize: "20px",
                }}
              >
                <b>CAMPO EXCLUSIVO DO CUIDADO FARMACÊUTICO</b>
              </p>
            </div>
          </div>
          <div className="div2">
            <div
              className="item"
              style={{ overflowY: "auto", maxHeight: "400px" }}
            >
              <table className="content-table" style={{ margin: "0px" }}>
                <thead>
                  <tr>
                    <th>Data</th>
                    <th>Informação</th>
                    <th>Farm. Responsável</th>
                  </tr>
                </thead>
                <tbody align="justify">
                  {listaFarmaceutico.map((item) => (
                    <tr>
                      <th>{formatDate(item.data)}</th>
                      <th>{item.informacao}</th>
                      <th>{item.responsavel}</th>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="div2">
            <div className="item">
              <p
                style={{
                  textAlign: "center",
                  background: "black",
                  verticalAlign: "center",
                }}
              ></p>
            </div>
          </div>
        </div>
      </div>
    </SidebarFarmaceutico>
  );
};

export default Formulario;
