import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import "./respostaTelec.css";
import React, { useState, useEffect } from "react";
import SidebarSolicitante from "../../components/sidebar_solicitante/Sidebar_Solicitante";
import Axios from "axios";
import { useHistory } from "react-router-dom";

const SolicitarTelec = (props) => {
  const [text, setText] = useState("");
  const [areaInteresse, setArea] = useState("");
  //const cpf = props.user.cpf;
  const [cpf, setCPF] = useState("");
  //const link = "https://atendimento.mdhmlg.vsoares.dev.br/room/" + cpf + areaInteresse
  const rand = parseInt(Math.random() * (99999999 - 10000000)) + +10000000;
  const link = "https://atendimento.mdhmlg.vsoares.dev.br/tc/" + rand;
  let history = useHistory();

  const solicitar = () => {
    Axios.defaults.withCredentials = false;
    console.log("chegou aqui");
    console.log(new Date());
    if (areaInteresse !== "") {
      Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/solicitarTelec", {
        assunto: text,
        cpf: cpf,
        area: areaInteresse,
      }).then((response) => {
        if (response.data.message == "0") {
          alert("Erro ao solicitar.");
        } else {
          alert("Solicitada com sucesso!");
          history.push("/solicitante/minhasTelec");
        }
      });
    } else {
      alert("Selecione uma área de interesse.");
    }
  };

  useEffect(() => {
    Axios.get("https://atendimento.mdhmlg.vsoares.dev.br:3002/login", {
      withCredentials: true,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }).then((response) => {
      if (response.data.loggedIn === true) {
        setCPF(response.data.user[0].cpf);
      }
    });
  }, []);

  return (
    <>
      <SidebarSolicitante>
        <div class="main__container">
          <div class="titulo">
            <h1>SOLICITAR TELECONSULTORIA</h1>
          </div>
          <div class="div_teste" style={{ height: "99%" }}>
            <div class="div2" style={{ width: "100%" }}>
              <div class="item" style={{ margin: "0" }}>
                <p className="paragrafo">
                  Sua dúvida medicamentosa está relacionada com qual área da
                  saúde?{" "}
                </p>
                <select
                  value={areaInteresse}
                  onChange={(e) => {
                    setArea(e.target.value);
                  }}
                >
                  <option></option>
                  <option>Nutrição</option>
                  <option>Psicologia</option>
                  <option>Psiquiatria</option>
                  <option>Neurologia</option>
                  <option>Oncologia</option>
                  <option>Cardiologia</option>
                  <option>Respiratórias</option>
                  <option>Emergência</option>
                  <option>Doenças metabólicas</option>
                  <option>Cirurgia</option>
                  <option>Odontologia</option>
                  <option>Microbiologia</option>
                  <option>Sistema Digestório</option>
                  <option>Doenças autoimunes</option>
                  <option>Outro</option>
                </select>
              </div>
            </div>
            <div
              className="editor"
              style={{ height: "55%", marginTop: "20px" }}
            >
              <CKEditor
                editor={ClassicEditor}
                data={text}
                config={{
                  toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "|",
                    "undo",
                    "redo",
                    "|",
                    "blockQuote",
                    "|",
                    "link",
                    "bulletedList",
                    "numberedList",
                    "|",
                    "indent",
                    "outdent",
                    "|",
                    "insertTable",
                  ],
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setText(data);
                }}
              />
            </div>
            <div className="responderDIV">
              <button style={{ width: "20%" }} onClick={solicitar}>
                Solicitar
              </button>
            </div>
          </div>
        </div>
      </SidebarSolicitante>
    </>
  );
};

export default SolicitarTelec;
