import React, { useEffect, useState } from "react";
import "./styles.css";
import "./lista.css";
import Axios from "axios";
import "./form.css";
import "./tabela.css";
import { Link, useHistory } from "react-router-dom";

const SidebarAdministrativo = (props) => {
  let history = useHistory();
  Axios.defaults.withCredentials = true;

  const [username, setUsuario] = useState([]);

  useEffect(() => {
    Axios.defaults.withCredentials = true;
    Axios.get("https://atendimento.mdhmlg.vsoares.dev.br:3002/login", {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }).then((response) => {
      if (response.data.loggedIn === true) {
        setUsuario(response.data.user[0]);
      }
    });
  }, []);

  const sair = () => {
    Axios.defaults.withCredentials = true;
    Axios.get("https://atendimento.mdhmlg.vsoares.dev.br:3002/logout", {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      } else {
        history.push("/login");
      }
    });
  };

  return (
    <>
      <div className="div_body">
        <div className="container">
          <nav className="navbar">
            <div className="nav_icon">
              <i className="fa fa-bars" aria-hidden="true"></i>
            </div>
            <div className="navbar__left">
              <a className="active_link" href="/">
                Painel
              </a>
            </div>
            <div className="navbar__right">
              <label>
                Olá, <b>{username.nome}</b>!
              </label>
            </div>
          </nav>
          <main>
            <div className="main__container" style={{ height: "100%" }}>
              {props.children}
            </div>
          </main>
          <div id="sidebar">
            <div className="sidebar__title">
              <div className="sidebar__img">
                <h1>MYDIGICARE - ADMINISTRATIVO</h1>
              </div>
              <i
                className="fa fa-times"
                id="sidebarIcon"
                aria-hidden="true"
              ></i>
            </div>
            <div className="sidebar__menu">
              <div className="sidebar__link">
                <i className="fa fa-home"></i>
                <Link to="/adm/dashboard">Dashboard</Link>
              </div>
              <div className="sidebar__link">
                <i className="fa fa-envelope-o"></i>
                <a href="https://webmail.mydigicare.com.br" target="_blank">
                  Email
                </a>
              </div>
              <div className="sidebar__link">
                <i className="fa fa-file"></i>
                <a href="https://drive.mydigicare.com.br" target="_blank">
                  Drive
                </a>
              </div>
              <div className="sidebar__link">
                <i class="fa fa-solid fa-lock"></i>
                <Link to="/adm/alterarSenha">Alterar minha senha</Link>
              </div>
              <div className="sidebar__link">
                <i class="fa fa-solid fa-key"></i>
                <Link to="/adm/alterarSenhaUsuarios">
                  Alterar senhas - Usuários
                </Link>
              </div>
              <br></br>
              <h2>CONFIGURAÇÕES</h2>
              <div className="sidebar__link">
                <i className="fa fa-id-card"></i>
                <Link to="/adm/cadastroColab">Cadastrar Colaborador - ADM</Link>
              </div>
              <div className="sidebar__link">
                <i className="fa fa-id-card"></i>
                <Link to="/adm/cadastroFarm">Cadastrar profissional</Link>
              </div>
              {/*
                            <div className="sidebar__link">
                                <i className="fa fa-id-card"></i>
                                <Link to='/adm/cadastroPac'>Cadastrar Paciente</Link>
                            </div>
              */}
              <br></br>
              <div class="teleconsultoria">
                <h2>TELECONSULTORIAS</h2>
                <div className="sidebar__link">
                  <i className="fa fa-search"></i>
                  <Link to="/adm/buscaTelec">Buscar Teleconsultoria</Link>
                </div>
                <div className="sidebar__link">
                  <i className="fa fa-clock-o"></i>
                  <Link to="/adm/telecAbertas">
                    Teleconsultorias Solicitadas
                  </Link>
                </div>
                <div className="sidebar__link">
                  <i className="fa fa-spinner"></i>
                  <Link to="/adm/telecAndamento">
                    Teleconsultorias em Andamento
                  </Link>
                </div>
                <div className="sidebar__link">
                  <i className="fa fa-check-square-o"></i>
                  <Link to="/adm/telecEncerradas">
                    Teleconsultorias Encerradas
                  </Link>
                </div>
              </div>
              <br></br>
              <div class="teleassistencia">
                <h2>TELEASSISTÊNCIAS</h2>
                <div className="sidebar__link">
                  <i className="fa fa-search"></i>
                  <Link to="/adm/buscaAgenda">
                    Buscar Agenda do Colaborador
                  </Link>
                </div>
                <div className="sidebar__link">
                  <i className="fa fa-search"></i>
                  <Link to="/adm/buscaTeleA">Buscar Teleassistência</Link>
                </div>
                <div className="sidebar__link">
                  <i className="fa fa-calendar-check-o"></i>
                  <Link to="/adm/Agenda">Agenda Geral</Link>
                </div>
                <div className="sidebar__link">
                  <i className="fa fa-plus"></i>
                  <Link to="/adm/marcar">
                    Marcar / Remarcar Teleassistência
                  </Link>
                </div>
                <div className="sidebar__link">
                  <i className="fa fa-minus"></i>
                  <Link to="/adm/desmarcar">Desmarcar Teleassistência</Link>
                </div>
                <div className="sidebar__link">
                  <i className="fa fa-clock-o"></i>
                  <Link to="/adm/alterarHorarioTeleA">
                    Alterar horário - Teleassistência
                  </Link>
                </div>
                <div className="sidebar__link">
                  <i className="fa fa-users"></i>
                  <Link to="/adm/alterarVinculoProntuario">
                    Alterar vinculação Profissional Prontuário{" "}
                  </Link>
                </div>
              </div>
              <br></br>
              <h2>RELATÓRIOS</h2>
              {/*
                            <div className="sidebar__link">
                                <i className="fa fa-list"></i>
                                <Link to='/adm/listaPacientes'>Lista de Pacientes</Link>
                            </div>
                            <div className="sidebar__link">
                                <i className="fa fa-list"></i>
                                <Link to='/adm/listaSolicitantes'>Lista de Solicitantes</Link>
                            </div>
                            */}
              <div className="sidebar__link">
                <i className="fa fa-list"></i>
                <Link to="/adm/listaFarmaceuticos">Lista de Farmacêuticos</Link>
              </div>
              <div className="sidebar__link">
                <i className="fa fa-list"></i>
                <Link to="/adm/ListaPsicologo">Lista de Psicologos</Link>
              </div>
              <div className="sidebar__link">
                <i className="fa fa-list"></i>
                <Link to="/adm/listaNutricionista">
                  Lista de Nutricionistas
                </Link>
              </div>
              <div className="sidebar__link">
                <i className="fa fa-list"></i>
                <Link to="/adm/ListaEducadorFisico">
                  Lista de Educadores Físicos
                </Link>
              </div>
              <br></br>
              <div className="sidebar__logout">
                <i className="fa fa-power-off"></i>
                <a href="/sair">Sair</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SidebarAdministrativo;
