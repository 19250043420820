import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import Registration from "./pages/Registration";
import Axios from "axios";

//Imports Geral
import Login from "./pages/Login/NovoLogin";
//Imports Administrativo
import Dashboard from "./pages/Administrativo/Dashboard.jsx";
import DesmarcarTeleA from "./pages/Administrativo/DesmarcarTeleA.jsx";
import MarcarTeleA from "./pages/Administrativo/MarcarTeleA.jsx";
import CadastroColab from "./pages/Administrativo/CadastroColab.jsx";
import CadastroFarm from "./pages/Administrativo/CadastroFarm.jsx";
import CadastroPac from "./pages/Administrativo/CadastroPac.jsx";
import TelecAberta from "./pages/Administrativo/TelecAberta.jsx";
import TelecAndamento from "./pages/Administrativo/TelecAndamento.jsx";
import TelecEncerrada from "./pages/Administrativo/TelecEncerrada.jsx";
import BuscaAgenda from "./pages/Administrativo/BuscaAgenda.jsx";
import BuscaTeleA from "./pages/Administrativo/BuscaTeleA.jsx";
import BuscaTelec from "./pages/Administrativo/BuscaTelec.jsx";
import Agenda from "./pages/Administrativo/Agenda.jsx";
import Formulario from "./pages/Administrativo/Formulario";
import ListaPacientes from "./pages/Administrativo/ListaPacientes";
import ListaFarmaceutico from "./pages/Administrativo/ListaFarmaceuticos";
import ListaNutricionista from "./pages/Administrativo/ListaNutricionista";
import ListaPsicologo from "./pages/Administrativo/ListaPsicologo";
import ListaSolicitantes from "./pages/Administrativo/ListaSolicitantes";
import DetalheConsultoria from "./pages/Administrativo/DetalheConsultoria";
import AlterarSenha from "./pages/Administrativo/AlterarSenha";
import AlterarFone from "./pages/Administrativo/AlterarFone";
import AlterarSenhaUsuarios from "./pages/Administrativo/AlterarSenhaUsuarios";
import AlterarVinculoProntuario from "./pages/Administrativo/AlterarVinculoProntuario";
import AlterarHorarioTeleAssistencia from "./pages/Administrativo/AlterarHorarioTeleA";
import ChatTeleconsultoriaAdmin from "./pages/Administrativo/ChatTeleconsultoria";

//Imports Farmaceutico
import AgendaFarm from "./pages/Farmaceutico/AgendaFarm";
import BuscarTelecFarm from "./pages/Farmaceutico/buscarTelec";
import BuscarTeleassFarm from "./pages/Farmaceutico/buscaTeleAssFarm";
import MarcarTeleAssFarm from "./pages/Farmaceutico/MarcarTeleassFarm";
import TelecAbertaFarm from "./pages/Farmaceutico/telecAbertaFarm";
import TelecAndamentoFarm from "./pages/Farmaceutico/telecAndamentoFarm";
import TelecEncerradaFarm from "./pages/Farmaceutico/telecEncerradaFarm";
import Principal from "./pages/Farmaceutico/Principal";
import FormFarm from "./pages/Farmaceutico/Formulario";
import DesmarcarTeleAFarm from "./pages/Farmaceutico/DesmarcarTeleA.jsx";
import RespostaTelec from "./pages/Farmaceutico/RespostaTelec";
import DetalheConsultoriaFarma from "./pages/Farmaceutico/DetalheConsultoria";
import AlterarSenhaFarmaceutico from "./pages/Farmaceutico/AlterarSenha";
import AlterarFoneFarmaceutico from "./pages/Farmaceutico/AlterarFone";
import AlterarHorarioTeleAssistenciaFarm from "./pages/Farmaceutico/AlterarHorarioTeleAFarm";
import VideoChamadaFarmaceutico from "./pages/Farmaceutico/VideoChamadaFarmaceutico";
import ChatTeleconsultoria from "./pages/Farmaceutico/ChatTeleconsultoria";

//Imports Solicitante
import MinhasTelecAndamento from "./pages/Solicitante/MinhasTelecAndamento";
import MinhasTelecEncerradas from "./pages/Solicitante/MinhasTelecEncerradas";
import MinhasTelecSolicitante from "./pages/Solicitante/MinhasTelecSolicitante";
import PrincipalSolicitante from "./pages/Solicitante/PrincipalSolicitante";
import SolicitarTelec from "./pages/Solicitante/SolicitarTelec";
import DetalhesConsultoria from "./pages/Solicitante/DetalheConsultoria";
import CadastroSolicitante from "./pages/Solicitante/CadastroSolicitante";
import ChatTeleconsultoriaSolicitante from "./pages/Solicitante/ChatTeleconsultoria";

//Imports Sala
import Room from "./pages/Sala/Room.jsx";
import CreateRoom from "./pages/Sala/CreateRoom";
import TelaPaciente from "./pages/Sala/TelaPaciente";

//Imports Rotas Privadas
import AdmRoute from "./routes/AdmRoute";
import FarmaRoute from "./routes/FarmaRoute";
import SolicitanteRoute from "./routes/SolicitanteRoute";

//Imports Home
import Home from "./pages/Home/Home";

function App() {
  Axios.defaults.withCredentials = true;
  const [username, setUsuario] = useState([]);

  useEffect(() => {
    Axios.defaults.withCredentials = true;

    Axios.get("https://atendimento.mdhmlg.vsoares.dev.br:3002/login", {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }).then((response) => {
      if (response.data.loggedIn === true) {
        setUsuario(response.data.user[0]);
      }
    });
  }, []);

  return (
    <Router>
      <Switch>
        {
          // Links gerais
        }
        <Route
          path="/registration"
          exact
          render={(props) => <Registration />}
        />
        <Route path="/" exact render={(props) => <Login />} />
        <Route path="/sair" exact render={(props) => <Registration />} />
        <Route path="/login" exact render={(props) => <Login />} />
        <Route path="/criarSala" exact component={CreateRoom} />
        <Route path="/room/:roomID" component={Room} />
        <Route path="/atendimento/:roomID" component={TelaPaciente} />
        {
          // Links solicitantes
        }
        <SolicitanteRoute
          path="/solicitante"
          exact
          render={(props) => <PrincipalSolicitante user={username} />}
        />
        <SolicitanteRoute
          path="/solicitante/minhasTelec"
          exact
          render={(props) => <MinhasTelecSolicitante user={username} />}
        />
        <SolicitanteRoute
          path="/solicitante/minhasTelecAndamento"
          exact
          render={(props) => <MinhasTelecAndamento user={username} />}
        />
        <SolicitanteRoute
          path="/solicitante/minhasTelecEncerradas"
          exact
          render={(props) => <MinhasTelecEncerradas user={username} />}
        />
        <SolicitanteRoute
          path="/solicitante/solicitarTelec"
          exact
          render={(props) => <SolicitarTelec user={username} />}
        />
        <SolicitanteRoute
          path="/solicitante/detalhes"
          exact
          render={(props) => <DetalhesConsultoria user={username} />}
        />
        <SolicitanteRoute
          path="/solicitante/chatTeleconsultoria"
          exact
          render={(props) => <ChatTeleconsultoriaSolicitante user={username} />}
        />
        <Route
          path="/solicitante/cadastro"
          exact
          render={(props) => <CadastroSolicitante />}
        />
        {
          // Links ADM
        }
        <AdmRoute
          path="/adm"
          exact
          render={(props) => <Dashboard user={username} />}
        />
        <AdmRoute
          path="/adm/detalhes"
          exact
          render={(props) => <DetalheConsultoria user={username} />}
        />
        <AdmRoute
          path="/adm/dashboard"
          exact
          render={(props) => <Dashboard user={username} />}
        />
        <AdmRoute
          path="/adm/cadastroFarm"
          exact
          render={(props) => <CadastroFarm user={username} />}
        />
        <AdmRoute
          path="/adm/cadastroPac"
          exact
          render={(props) => <CadastroPac user={username} />}
        />
        <AdmRoute
          path="/adm/cadastroColab"
          exact
          render={(props) => <CadastroColab user={username} />}
        />
        <AdmRoute
          path="/adm/buscaTelec"
          exact
          render={(props) => <BuscaTelec user={username} />}
        />
        <AdmRoute
          path="/adm/buscaTeleA"
          exact
          render={(props) => <BuscaTeleA user={username} />}
        />
        <AdmRoute
          path="/adm/telecEncerradas"
          exact
          render={(props) => <TelecEncerrada user={username} />}
        />
        <AdmRoute
          path="/adm/telecAndamento"
          exact
          render={(props) => <TelecAndamento user={username} />}
        />
        <AdmRoute
          path="/adm/telecAbertas"
          exact
          render={(props) => <TelecAberta user={username} />}
        />
        <AdmRoute
          path="/adm/marcar"
          exact
          render={(props) => <MarcarTeleA user={username} />}
        />
        <AdmRoute
          path="/adm/desmarcar"
          exact
          render={(props) => <DesmarcarTeleA user={username} />}
        />
        <AdmRoute
          path="/adm/buscaAgenda"
          exact
          render={(props) => <BuscaAgenda user={username} />}
        />
        <AdmRoute
          path="/adm/Agenda"
          exact
          render={(props) => <Agenda user={username} />}
        />
        <AdmRoute
          path="/adm/form"
          exact
          render={(props) => <Formulario user={username} />}
        />
        <AdmRoute
          path="/adm/alterarFone"
          exact
          render={(props) => <AlterarFone user={username} />}
        />
        <AdmRoute
          path="/adm/listaPacientes"
          exact
          render={(props) => <ListaPacientes user={username} />}
        />
        <AdmRoute
          path="/adm/listaFarmaceuticos"
          exact
          render={(props) => <ListaFarmaceutico user={username} />}
        />
        <AdmRoute
          path="/adm/ListaNutricionista"
          exact
          render={(props) => <ListaNutricionista user={username} />}
        />
        <AdmRoute
          path="/adm/ListaPsicologo"
          exact
          render={(props) => <ListaPsicologo user={username} />}
        />
        <AdmRoute
          path="/adm/listaFarmaceuticos"
          exact
          render={(props) => <ListaFarmaceutico user={username} />}
        />
        <AdmRoute
          path="/adm/listaSolicitantes"
          exact
          render={(props) => <ListaSolicitantes user={username} />}
        />
        <AdmRoute
          path="/adm/alterarSenha"
          exact
          render={(props) => <AlterarSenha user={username} />}
        />
        <AdmRoute
          path="/adm/alterarSenhaUsuarios"
          exact
          render={(props) => <AlterarSenhaUsuarios user={username} />}
        />
        <AdmRoute
          path="/adm/alterarVinculoProntuario"
          exact
          render={(props) => <AlterarVinculoProntuario user={username} />}
        />
        <AdmRoute
          path="/adm/alterarHorarioTeleA"
          exact
          render={(props) => <AlterarHorarioTeleAssistencia user={username} />}
        />
        <AdmRoute
          path="/adm/chatTeleconsultoria"
          exact
          render={(props) => <ChatTeleconsultoriaAdmin user={username} />}
        />
        {
          // Links Farmaceutico
        }
        <FarmaRoute
          path="/farmaceutico/"
          exact
          render={(props) => <Principal />}
        />
        <FarmaRoute
          path="/farmaceutico/buscarTelec"
          exact
          render={(props) => <BuscarTelecFarm user={username} />}
        />
        <FarmaRoute
          path="/farmaceutico/telecAbertas"
          exact
          render={(props) => <TelecAbertaFarm user={username} />}
        />
        <FarmaRoute
          path="/farmaceutico/telecAndamento"
          exact
          render={(props) => <TelecAndamentoFarm user={username} />}
        />
        <FarmaRoute
          path="/farmaceutico/telecEncerrada"
          exact
          render={(props) => <TelecEncerradaFarm user={username} />}
        />
        <FarmaRoute
          path="/farmaceutico/buscaTeleass"
          exact
          render={(props) => <BuscarTeleassFarm user={username} />}
        />
        <FarmaRoute
          path="/farmaceutico/agenda"
          exact
          render={(props) => <AgendaFarm user={username} />}
        />
        <FarmaRoute
          path="/farmaceutico/marcar"
          exact
          render={(props) => <MarcarTeleAssFarm user={username} />}
        />
        <FarmaRoute
          path="/farmaceutico/form"
          exact
          render={(props) => <FormFarm user={username} />}
        />
        <FarmaRoute
          path="/farmaceutico/responderTelec"
          exact
          render={(props) => <RespostaTelec user={username} />}
        />
        <FarmaRoute
          path="/farmaceutico/detalhes"
          exact
          render={(props) => <DetalheConsultoriaFarma user={username} />}
        />
        <FarmaRoute
          path="/farmaceutico/desmarcarTeleassistencia"
          exact
          render={(props) => <DesmarcarTeleAFarm user={username} />}
        />
        <FarmaRoute
          path="/farmaceutico/alterarSenha"
          exact
          render={(props) => <AlterarSenhaFarmaceutico user={username} />}
        />
        <FarmaRoute
          path="/farmaceutico/alterarFone"
          exact
          render={(props) => <AlterarFoneFarmaceutico user={username} />}
        />
        <FarmaRoute
          path="/farmaceutico/alterarHorarioTeleA"
          exact
          render={(props) => (
            <AlterarHorarioTeleAssistenciaFarm user={username} />
          )}
        />
        <FarmaRoute
          path="/farmaceutico/roomTelec"
          exact
          render={(props) => <VideoChamadaFarmaceutico user={username} />}
        />
        <FarmaRoute
          path="/farmaceutico/chatTeleconsultoria"
          exact
          render={(props) => <ChatTeleconsultoria user={username} />}
        />
      </Switch>
    </Router>
  );
}

export default App;
